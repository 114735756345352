import { render, staticRenderFns } from "./HomepageEngagement.vue?vue&type=template&id=e06d5478&scoped=true&"
import script from "./HomepageEngagement.vue?vue&type=script&lang=js&"
export * from "./HomepageEngagement.vue?vue&type=script&lang=js&"
import style0 from "./HomepageEngagement.vue?vue&type=style&index=0&id=e06d5478&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e06d5478",
  null
  
)

export default component.exports